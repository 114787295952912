import React, {  Component  } from "react";
import { gsap } from "gsap";
import { TweenLite } from "gsap/all";
import { Draggable } from "gsap/Draggable";
gsap.registerPlugin(Draggable);



//export const PSlider = (props) => {
export default class PSlider extends Component{

  constructor(props) {
   super(props);


   this.state = {
     dragging:false
   };
 }

 dragChange=(event)=>{
   this.setState({dragging:true})
   let s=Draggable.get(event.target)
   //let v=50
//     s=Draggable.get(".slider"+this.props.attrib.oid1)

  if(s==undefined){
       s=Draggable.get(".slider"+this.props.attrib.oid1)

  }

   let  v=(100-(((s.y-s.minY)/this.props.sy)*100).toFixed(0))
   console.log(v)

   this.props.onAfterChange(this.props.attrib,"sliderUpdate",v)


}
 dragEnd=(event)=>{
   //console.log("DragEnd")
   this.setState({dragging:false})

   let s=Draggable.get(event.target)
   if(s==undefined){
        s=Draggable.get(".slider"+this.props.attrib.oid1)
   }
//   let v=50
  // try{
    let v=(100-(((s.y-s.minY)/this.props.sy)*100).toFixed(0))
  //}

  //catch(err){}
   this.props.onAfterChange(this.props.attrib,"slider",v)

 }


  componentDidUpdate(prevProps, prevState) {

    if(this.state.dragging===false)
      if (prevProps.value !== this.props.value){

        TweenLite.to(".slider"+this.props.attrib.oid1,{y:(this.props.sy+this.props.y)-((this.props.value/100)*this.props.sy)});
      }
}

 componentDidMount(){


/*
minX: -sliderRef.current.clientWidth + window.innerWidth * 0.88,
maxX: 0,
*/


TweenLite.set(".slider"+this.props.attrib.oid1,{y:(this.props.sy+this.props.y)-((this.props.value/100)*this.props.sy)});

    //console.log(sliderRef.current.clientWidth, sliderRef.current.innerWidth);
    Draggable.create(".slider"+this.props.attrib.oid1, {
      type: "cy",
      bounds: {
        minY:this.props.y,
        maxY:parseInt(this.props.y)+parseInt(this.props.sy)
      },
      onDrag:this.dragChange,
      onDragEnd:this.dragEnd
    });

}

clicked=(v)=>{
  console.log("clicked "+v)
  this.props.onAfterChange(this.props.attrib,"slider",v)
}


render(){
   //this.sliderRef = useRef(null);
  this.ref="slider"+this.props.attrib.oid1
  let props=this

  //console.log("Slider")
//  console.log(props)
  return (
    <g className="pslider" id="slider2" x={this.props.x} y={this.props.y} >
    <line x1={this.props.x} y1={this.props.y} x2={this.props.x} y2={this.props.y+this.props.sy} strokeWidth="1" stroke="#606060" />
    <line className="sliderblue" x1={this.props.x} y1={this.props.y+this.props.sy} x2={this.props.x} y2={this.props.y+this.props.sy-this.props.value*2} strokeWidth="1" stroke="#007dff" />
        <rect width="10" height="20" x={this.props.x-5} y={this.props.y+(this.props.sy)*0} onClick={()=>this.clicked(100)} opacity="0"></rect>
        <rect width="10" height="20" x={this.props.x-5} y={this.props.y+(this.props.sy)*0.1} onClick={()=>this.clicked(90)} opacity="0"></rect>
        <rect width="10" height="20" x={this.props.x-5} y={this.props.y+(this.props.sy)*0.2} onClick={()=>this.clicked(80)} opacity="0"></rect>
        <rect width="10" height="20" x={this.props.x-5} y={this.props.y+(this.props.sy)*0.3} onClick={()=>this.clicked(70)} opacity="0"></rect>
        <rect width="10" height="20" x={this.props.x-5} y={this.props.y+(this.props.sy)*0.4} onClick={()=>this.clicked(60)} opacity="0"></rect>
        <rect width="10" height="20" x={this.props.x-5} y={this.props.y+(this.props.sy)*0.5} onClick={()=>this.clicked(50)} opacity="0"></rect>
        <rect width="10" height="20" x={this.props.x-5} y={this.props.y+(this.props.sy)*0.6} onClick={()=>this.clicked(40)} opacity="0" ></rect>
        <rect width="10" height="20" x={this.props.x-5} y={this.props.y+(this.props.sy)*0.7} onClick={()=>this.clicked(30)} opacity="0"></rect>
        <rect width="10" height="20" x={this.props.x-5} y={this.props.y+(this.props.sy)*0.8} onClick={()=>this.clicked(15)} opacity="0"></rect>
        <rect width="10" height="20" x={this.props.x-5} y={this.props.y+(this.props.sy)*0.9} onClick={()=>this.clicked(0)} opacity="0"></rect>

        <circle className={"slider"+this.props.attrib.oid1}  cy={props.y}  cx={this.props.x}       r={7.5} fill="#FFFFFF" strokeWidth="30" stroke="#00000000" ></circle>
    </g>
  );
}
};
