
import React, { Component }  from 'react';



import PSlider from '../Components/PSlider.js'




function getVariable(vars,id,default_val="---"){

  let value=default_val
  if ( (id in vars) ){
      value=vars[id][4]
  }
  return value


}


function colorHex(n){
  let b=(n&255).toString(16);;
  let g=((n>>8)&255).toString(16);;
  let r=((n>>16)&255).toString(16);;

  return "#"+r+g+b;
}

function swf2png(img){

  if(img==""){
    return ""
  }

  if(img.includes(".swf")){
    return "/img/"+img.replace(".swf",".png")
  }
  return "/img/"+img
}

function dec2bin(dec){
    return (dec >>> 0).toString(2);
}

function pad(value,target_length,pad_value="0"){
  while(value.length<target_length){
    value=pad_value+value
  }
  return value
}


function EL(props){
  let offset_y=0

  //Sometimes we need to insert gui vars or make an element reflect the state returned,
  //this is true for weather request
  // 100 values where object id is >1




  if(props.tid==100){
    let txt_string=props.txt
      let value="---"
      let offset=0
      try{
        offset=parseInt(props.ost1)
      }catch(err){
        offset=0
      }
      if(offset==undefined){
        offset=0
      }

    if(props.oid1!="-1" && props.oid2!="-1"){
      //%f1 &#176;C
    //  console.log("Vars")
    //  console.log(props)
    //  console.log(props.vars)
      txt_string=props.oid2
      console.log(txt_string)
      value="---"
      if ( (props.oid1 in props.vars) ){
           value=props.vars[props.oid1][4+offset]
      }
      if(value=="---"){
        txt_string="---"//txt_string.replace("%f1",value)
      }
      else if(txt_string.includes("%f1")){
        //Need to replace this element with value. could also be a % value?
      //  console.log("F1"+txt_string)
        txt_string=txt_string.replace("%f1",parseFloat(value).toFixed(1))
      //  console.log(txt_string)
      }
      else if(txt_string.includes("%f0")){
    //    console.log("F0"+txt_string)
        //Need to replace this element with value. could also be a % value?
        txt_string=txt_string.replace("%f0",parseFloat(value).toFixed(0))
    //    console.log(txt_string)
      }

    }
    if(props.oid1!="-1" && props.oid2=="-1"){
      //Just a parameter
      value="---"
      if ( (props.oid1 in props.vars) ){
           value=props.vars[props.oid1][4]
      }
      txt_string=value
    }
//alignment-baseline="hanging"

    return (<g key={"EL"+props.id} className="svgButton" tid={props.tid} onClick={()=>props.callback(props.attrib,"button",0)}>   <text x={props.px} y={props.py} alignment-baseline="hanging" fill={colorHex(props.fc)} fontFamily={props.fn} fontSize={props.fs}  dangerouslySetInnerHTML={{__html: txt_string}} ></text></g>)
  }

  if(props.tid==102){
    let txt_string=props.txt
      let value="---"
      let offset=0
      try{
        offset=parseInt(props.ost1)
      }catch(err){
        offset=0
      }
      if(offset==undefined){
        offset=0
      }



    if(props.oid1!="-1" && props.oid2!="-1"){
      //%f1 &#176;C
    //  console.log("Vars")
    //  console.log(props)
    //  console.log(props.vars)
      txt_string=props.oid2
console.log(txt_string)
      if ( (props.oid1 in props.vars) ){
           value=props.vars[props.oid1][4+offset]
      }
      if(value=="---"){
        txt_string="---"//txt_string.replace("%f1",value)
      }
      else if(txt_string.includes("%f1")){
        //Need to replace this element with value. could also be a % value?
      //  console.log("F1"+txt_string)
        txt_string=txt_string.replace("%f1",parseFloat(value).toFixed(1))
    //    console.log(txt_string)
      }
      else if(txt_string.includes("%f0")){
    //    console.log("F0"+txt_string)
        //Need to replace this element with value. could also be a % value?
        txt_string=txt_string.replace("%f0",parseFloat(value).toFixed(0))
      //  console.log(txt_string)
      }
    }
    if(props.oid1!="-1" && props.oid2=="-1"){
      //Just a parameter
      value="---"
      if ( (props.oid1 in props.vars) ){
           value=props.vars[props.oid1][4]
      }
      txt_string=value
    }

    return (<g key={"EL"+props.id} className="svgButton" tid={props.tid} onClick={()=>props.callback(props.attrib,"button",0)}>
     <text x={props.px} y={parseInt(props.py)+10} fill={colorHex(props.fc)} fontFamily={props.fn} fontSize={props.fs} dangerouslySetInnerHTML={{__html: txt_string}}  ></text> </g>)
  }

  if(props.tid==30){


//c_blue

//c_blue c_red c_green


    return (<g key={"EL"+props.id} className="svgButton"   tid={props.tid}onClick={()=>props.callback(props.attrib,"button",0)}>
    <rect x={props.px} y={parseInt(props.py)+offset_y} width="80" height="80" rx="15" fill="url('#greyBut')"/>
    <image xlinkHref={swf2png(props.img)} x={parseInt(props.px)+10} y={parseInt(props.py)+offset_y+4} width={parseInt(props.sx)-20} height={parseInt(props.sy)-20} />
    <text x={parseInt(props.px)+parseInt(props.sx)/2} y={parseInt(props.py)-(parseInt(props.sy)/2)+offset_y+110} fontSize={props.fs} fill={colorHex(props.fc)} dominantBaseline="middle" textAnchor="middle"  >{props.txt}</text>
    </g>)
  }

  if(props.tid==31){


    return (<g key={"EL"+props.id} className="svgButton" tid={props.tid} onClick={()=>props.callback(props.attrib,"button",0)} >
    <rect x={props.px} y={parseInt(props.py)+offset_y} width={parseInt(props.sx)} height={parseInt(props.sy)} rx="10" fill="url('#greyBut')"/>
    <image xlinkHref={swf2png(props.img)} x={parseInt(props.px)+10} y={parseInt(props.py)+offset_y+8} width={parseInt(props.sx)-20} height={parseInt(props.sy)-20} />
    <text x={parseInt(props.px)+parseInt(props.sx)/2} y={parseInt(props.py)-(parseInt(props.sy)/2)+offset_y+60} fontSize={props.fs} fill={colorHex(props.fc)} dominantBaseline="middle" textAnchor="middle"  >{props.txt}</text>
    </g>)
  }

  if(props.tid==32){


    return (<g key={"EL"+props.id} className="svgButton"  tid={props.tid} onClick={()=>props.callback(props.attrib,"button",0)}>
    <rect x={props.px} y={parseInt(props.py)+offset_y} width={parseInt(props.sx)} height={parseInt(props.sy)} rx="10" fill="url('#greyBut')"/>
    <image xlinkHref={swf2png(props.img)} x={parseInt(props.px)+10} y={parseInt(props.py)+offset_y+8} width={parseInt(props.sx)-20} height={parseInt(props.sy)-20} />
    <text x={parseInt(props.px)+parseInt(props.sx)/2} y={parseInt(props.py)-(parseInt(props.sy)/2)+offset_y+60} fontSize={props.fs} fill={colorHex(props.fc)} dominantBaseline="middle" textAnchor="middle"  >{props.txt}</text>
    </g>)
  }
  if(props.tid==33){
    return (<g key={"EL"+props.id} className="svgButton"  tid={props.tid} onClick={()=>props.callback(props.attrib,"button",0)}>
    <rect x={props.px} y={parseInt(props.py)+offset_y} width={parseInt(props.sx)} height={parseInt(props.sy)} rx="10" fill="url('#greyBut')"/>
    <image xlinkHref={swf2png(props.img)} x={parseInt(props.px)+10} y={parseInt(props.py)+offset_y+8} width={parseInt(props.sx)-20} height={parseInt(props.sy)-20} />
    <text x={parseInt(props.px)+parseInt(props.sx)/2} y={parseInt(props.py)-(parseInt(props.sy)/2)+offset_y+60} fontSize={props.fs} fill={colorHex(props.fc)} dominantBaseline="middle" textAnchor="middle"  >{props.txt}</text>
    </g>)
  }


  if(props.tid==34){
    return (<g key={"EL"+props.id} className="svgButton"  onClick={()=>props.callback(props.attrib,"button",0)}>
    <rect x={props.px} y={parseInt(props.py)+offset_y} width={parseInt(props.sx)} height={parseInt(props.sy)} rx="10" fill="url('#greyBut')"/>
    <image xlinkHref={swf2png(props.img)} x={parseInt(props.px)+10} y={parseInt(props.py)+offset_y+8} width={parseInt(props.sx)-20} height={parseInt(props.sy)-20} />
    <text x={parseInt(props.px)+parseInt(props.sx)/2} y={parseInt(props.py)-(parseInt(props.sy)/2)+offset_y+60} fontSize={props.fs} fill={colorHex(props.fc)} dominantBaseline="middle" textAnchor="middle"  >{props.txt}</text>
    </g>)
  }

  if(props.tid==35){

    //<el id="9" tid="35" px="575" py="30" sx="60" sy="60" oid1="409876" oid2="-1" oid3="0" oid4="-1" ost1="0" ost2="0" type="1" txt="Open" fn="Arial" fs="12" fb="1" fi="0" fc="16777215" co="8421504" img="" img2="" page="" pin="" kc="1621272"/>
    return (<g key={"EL"+props.id} className="svgButton"  onClick={()=>props.callback(props.attrib,"button",0)}>
    <rect x={props.px} y={parseInt(props.py)+offset_y} width={parseInt(props.sx)} height={parseInt(props.sy)} rx="10" fill="url('#greyBut')"/>
<image xlinkHref={swf2png(props.img)} x={parseInt(props.px)+10} y={parseInt(props.py)+offset_y} width={parseInt(props.sx)-20} height={parseInt(props.sy)-20} />
    <text x={parseInt(props.px)+parseInt(props.sx)/2} y={parseInt(props.py)-(parseInt(props.sy)/2)+offset_y+60} fontSize={props.fs} fill={colorHex(props.fc)} dominantBaseline="middle" textAnchor="middle"  >{props.txt}</text>
    </g>)
  }

  if(props.tid==1){
    //<el id="57" tid="1" px="430" py="5" sx="60" sy="60" oid1="14531" oid2="-1" oid3="0" oid4="-1" ost1="0" ost2="0" type="1" txt=" " fn="Arial" fs="12" fb="1" fi="0" fc="16777215" co="16711680" img="B1_Temp_Minus.png" img2="" page="" pin="" kc="0"/>
    //16777215  0xffffff
    //16711680  0xff0000


    return (<g key={"EL"+props.id} className="svgButton"  onClick={()=>props.callback(props.attrib,"button",0)}>
    <rect x={props.px} y={parseInt(props.py)+offset_y} width={parseInt(props.sx)} height={parseInt(props.sy)} rx="10" fill="#cccccc"/>
    <image xlinkHref={swf2png(props.img)} x={parseInt(props.px)+10} y={parseInt(props.py)+offset_y} width={parseInt(props.sx)-20} height={parseInt(props.sy)-20} />
    <text x={parseInt(props.px)+parseInt(props.sx)/2} y={parseInt(props.py)-(parseInt(props.sy)/2)+offset_y+105} fontSize={props.fs} fill={colorHex(props.fc)} dominantBaseline="middle" textAnchor="middle"  fontFamily={props.fn}>{props.txt}</text>
    </g>)
  }



  if(props.tid==36){
    return (<g key={"EL"+props.id} className="svgButton"  onClick={()=>props.callback(props.attrib,"button",0)}>
    <rect x={props.px} y={parseInt(props.py)+offset_y} width="40" height="40" rx="5" fill="url('#greyBut')"/>
    <image xlinkHref={swf2png(props.img)} x={parseInt(props.px)+2.5} y={parseInt(props.py)+offset_y+2.5} width={parseInt(props.sx)-5} height={parseInt(props.sy)-5} />
    <text x={parseInt(props.px)+parseInt(props.sx)/2} y={parseInt(props.py)-(parseInt(props.sy)/2)+offset_y+40} fontSize={props.fs} fill={colorHex(props.fc)} dominantBaseline="middle" textAnchor="middle"  >{props.txt}</text>
    </g>)
  }
  if(props.tid==37){
    return (<g key={"EL"+props.id} className="svgButton"  onClick={()=>props.callback(props.attrib,"button",0)}>
    <rect x={props.px} y={parseInt(props.py)+offset_y} width="40" height="40" rx="5" fill="url('#greyBut')"/>
    <image xlinkHref={swf2png(props.img)} x={parseInt(props.px)+2.5} y={parseInt(props.py)+offset_y+2.5} width={parseInt(props.sx)-5} height={parseInt(props.sy)-5} />
    <text x={parseInt(props.px)+parseInt(props.sx)/2} y={parseInt(props.py)-(parseInt(props.sy)/2)+offset_y+40} fontSize={props.fs} fill={colorHex(props.fc)} dominantBaseline="middle" textAnchor="middle"  >{props.txt}</text>
    </g>)
  }
  if(props.tid==600){
    //its the heating
    //console.log("Heating")
    //["T_Heat_WE_Night", "4", "0", "0", "1", "0", "000"]
    let value=["T_Heat_WE_Night", "4", "0", "0", "1", "0", "000"]
    if ( (props.oid1 in props.vars) ){
        value=props.vars[props.oid1]
    }
    let time=new Date(value[6] * 1000).toISOString().substr(11, 5)
    let days=pad(dec2bin(value[5]),8,"0")
    let enabled=value[2]
    days=days.split("").reverse()
    //console.log(days)

    //Slot 0 var name
    //slot 1 no idea
    //slot 2 enabled/disabled 1/0
    //slot 3  ?
    //slot 4 ?
    //slot 5 days
    //slot 6 seconds into day





    //<el id="74" tid="600" px="405" py="125" sx="160" sy="60" oid1="15244" oid2="-1" oid3="" oid4="-1"
    // ost1="0" ost2="0" type="7" txt="ON" fn="Arial" fs="12" fb="1" fi="0" fc="16777215" co="11184810" img="" img2="" page="" pin="" kc="0"/>
    return(

              <g key={"EL"+props.id} tid={props.tid}>
      <rect x={props.px} y={parseInt(props.py)+offset_y} width={parseInt(props.sx)} height={parseInt(props.sy)} rx="5" fill="#000000aa" stroke="#FFFFFF"/>
      <g onClick={()=>props.callback(props.attrib,"schedule_toggle",enabled)}>
      <rect x={parseInt(props.px)+5} y={parseInt(props.py)+offset_y+18} width={20} height={20} rx="2" fill="#00000000" stroke="#FFFFFF"/>
      <rect opacity={enabled} x={parseInt(props.px)+7.5} y={parseInt(props.py)+offset_y+20.5} width={15} height={15} rx="2" fill="#00FF00" />
      </g>
        <text x={parseInt(props.px)+5} y={parseInt(props.py)+12} fontSize="12px" fill={colorHex(props.fc)} dominantBaseline="left" textAnchor="left"  >{props.txt}</text>
        <text x={parseInt(props.px)+parseInt(props.sx)/2} y={parseInt(props.py)-(parseInt(props.sy)/2)+offset_y+60} fontSize="20px" fill={colorHex(props.fc)} dominantBaseline="middle" textAnchor="middle"  >{time}</text>

        <text opacity={days[1]} x={parseInt(props.px)+20} y={parseInt(props.py)+50} fontSize="10px" fill={colorHex(props.fc)} dominantBaseline="middle" textAnchor="middle"  >Mo</text>
        <text opacity={days[2]} x={parseInt(props.px)+40} y={parseInt(props.py)+50} fontSize="10px" fill={colorHex(props.fc)} dominantBaseline="middle" textAnchor="middle"  >Tu</text>
        <text opacity={days[3]} x={parseInt(props.px)+60} y={parseInt(props.py)+50} fontSize="10px" fill={colorHex(props.fc)} dominantBaseline="middle" textAnchor="middle"  >We</text>
        <text opacity={days[4]} x={parseInt(props.px)+80} y={parseInt(props.py)+50} fontSize="10px" fill={colorHex(props.fc)} dominantBaseline="middle" textAnchor="middle"  >Th</text>
        <text opacity={days[5]} x={parseInt(props.px)+100} y={parseInt(props.py)+50} fontSize="10px" fill={colorHex(props.fc)} dominantBaseline="middle" textAnchor="middle"  >Fr</text>
        <text opacity={days[6]} x={parseInt(props.px)+120} y={parseInt(props.py)+50} fontSize="10px" fill={colorHex(props.fc)} dominantBaseline="middle" textAnchor="middle"  >Sa</text>
        <text opacity={days[0]} x={parseInt(props.px)+140} y={parseInt(props.py)+50} fontSize="10px" fill={colorHex(props.fc)} dominantBaseline="middle" textAnchor="middle"  >Su</text>
        <g onClick={()=>props.callback(props.attrib,"schedule_edit",0)}>
        <rect x={parseInt(props.px)+30} y={parseInt(props.py)+offset_y} width={parseInt(props.sx)-30} opacity="0" height={parseInt(props.sy)} rx="5" fill="#000000" />
        </g>
            </g>)

  }


  if(props.tid==2){
    //<el id="83" tid="2" px="255" py="305" sx="60" sy="60" oid1="15434" oid2="-1" oid3="0" oid4="-1" ost1="0" ost2="0" type="1" txt="Off" fn="Arial" fs="12" fb="1" fi="0" fc="16777215" co="255" img="" img2="" page="" pin="" kc="0"/>
    return (<g key={"EL"+props.id} tid={props.tid} className="svgButton" onClick={()=>props.callback(props.attrib,"button",0)}>
    <rect x={props.px} y={parseInt(props.py)+offset_y} width={parseInt(props.sx)} height={parseInt(props.sy)} rx="10" fill="url('#greyBut')"/>
    <text x={parseInt(props.px)+parseInt(props.sx)/2} y={parseInt(props.py)-(parseInt(props.sy)/2)+offset_y+60} fontSize={props.fs} fill={colorHex(props.fc)} dominantBaseline="middle" textAnchor="middle"  >{props.txt}</text>
    </g>)
  }

  if(props.tid==3){
    //<el id="84" tid="3" px="375" py="305" sx="60" sy="60" oid1="15354" oid2="-1" oid3="0" oid4="-1" ost1="0" ost2="0" type="1" txt="On" fn="Arial" fs="12" fb="1" fi="0" fc="16777215" co="65280" img="" img2="" page="" pin="" kc="0"/>
    return(<g key={"EL"+props.id} tid={props.tid} className="svgButton" onClick={()=>props.callback(props.attrib,"button",0)}>
    <rect x={props.px} y={parseInt(props.py)+offset_y} width={parseInt(props.sx)} height={parseInt(props.sy)} rx="10" fill="url('#greyBut')"/>
    <text x={parseInt(props.px)+parseInt(props.sx)/2} y={parseInt(props.py)-(parseInt(props.sy)/2)+offset_y+60} fontSize={props.fs} fill={colorHex(props.fc)} dominantBaseline="middle" textAnchor="middle"  >{props.txt}</text>
    </g>)

  }

  if(props.tid==1100){
    let value=0
    let fill="url('#greyBut')"
try{
  if ( (props.oid1 in props.vars) ){

      value=parseInt(props.vars[props.oid1][3])
//      console.log("===================== "+value)
      value=value^1
  }

  if(value==1){
    fill="url('#greyBut1')"
  }


  //value=parseInt(getVariable(props.vars,props.oid1,0))

}catch(err){}

    return (<g key={"EL"+props.id} tid={props.tid} className="svgButton"  onClick={()=>props.callback(props.attrib,"toggle",value)}>
    <rect x={props.px} y={parseInt(props.py)+offset_y} width={parseInt(props.sx)} height={parseInt(props.sy)} rx="10" fill={fill}/>
    <image xlinkHref={swf2png(props.img)} x={parseInt(props.px)+10} y={parseInt(props.py)+offset_y} width={parseInt(props.sx)-20} height={parseInt(props.sy)-20} />
    <text x={parseInt(props.px)+parseInt(props.sx)/2} y={parseInt(props.py)-(parseInt(props.sy)/2)+offset_y+60} fontSize={props.fs} fill={colorHex(props.fc)} dominantBaseline="middle" textAnchor="middle"  >{props.txt}</text>
    </g>)
  }

  if(props.tid==1200){
    /*<el
    id="85" tid="1200" px="335" py="325" sx="20" sy="20"
    oid1="15537" oid2="3" oid3="" oid4="-1"
    ost1="0" ost2="0" type="13" txt="On/Off"
    fn="Arial" fs="12" fb="1" fi="0" fc="16777215" co="16711680" img="" img2="" page="" pin="" kc="0" bckImg1="img20_20_0_0_1.png" bckImg2="img20_20_0_0_2.png"/>

    //On off alternate between two images
    <image  x={parseInt(props.px)+2.5} y={parseInt(props.py)+offset_y+2.5} width={parseInt(props.sx)-5} height={parseInt(props.sy)-5} />
    */
    //let v=getVariable(props.oid1,0)
    let value=0
//    console.log(props.oid1)
    if ( (props.oid1 in props.vars) ){
        value=parseInt(props.vars[props.oid1][3])
    //    console.log("Val from mem:"+value )
    }
  //  console.log("bckImg"+parseInt(value+1))
    //console.log("HEATING")
    // /console.log(v)// onClick={()=>props.callback(props.attrib,"button",0)
    return(<g key={"EL"+props.id} tid={props.tid}>
    <rect x={parseInt(props.px)+2.5} y={parseInt(props.py)+offset_y+2.5} width={parseInt(props.sx)-7} height={parseInt(props.sy)-7}  fill="#00FF00" />
    <image xlinkHref={swf2png(props["bckImg"+parseInt(value+1)])} x={parseInt(props.px)+2.5} y={parseInt(props.py)+offset_y+2.5} width={parseInt(props.sx)-5} height={parseInt(props.sy)-5} />
    <text x={parseInt(props.px)+parseInt(props.sx)/2} y={parseInt(props.py)-(parseInt(props.sy)/2)+offset_y} fontSize={props.fs} fill={colorHex(props.fc)} dominantBaseline="middle" textAnchor="middle"  >{props.txt}</text>
    </g>)


  }

  if(props.tid==1300){
    //<el id="1" tid="1300" px="155" py="180" sx="240" sy="180" oid1="" oid2="" oid3="" oid4="-1" ost1="0" ost2="0" type="14" txt="" fn="Arial" fs="12" fb="1" fi="0" fc="16777215" co="4210752" img="Controlight_transp2.png" img2="" page="" pin="" kc="1992810884"/>
    return(<g key={"EL"+props.id} tid={props.tid} className="svgButton" onClick={()=>props.callback(props.attrib,"button",0)}>


    <image xlinkHref={swf2png(props.img)} x={parseInt(props.px)} y={parseInt(props.py)} width={parseInt(props.sx)} height={parseInt(props.sy)} />

    </g>)



  }

  if(props.tid==1301){
    //<el id="1" tid="1300" px="155" py="180" sx="240" sy="180" oid1="" oid2="" oid3="" oid4="-1" ost1="0" ost2="0" type="14" txt="" fn="Arial" fs="12" fb="1" fi="0" fc="16777215" co="4210752" img="Controlight_transp2.png" img2="" page="" pin="" kc="1992810884"/>
    return(<g key={"EL"+props.id}  tid={props.tid} className="svgButton" onClick={()=>props.callback(props.attrib,"button",0)}>


    <image xlinkHref={swf2png(props.img)} x={parseInt(props.px)} y={parseInt(props.py)} width={parseInt(props.sx)} height={parseInt(props.sy)} />

    </g>)



  }
  //<el id="32" tid="100" px="260" py="350" sx="54" sy="15" oid1="141827" oid2="%f1 &#176;C" oid3="" oid4="-1" ost1="0" ost2="0" type="2" txt="Rm Temp" fn="Arial" fs="12" fb="1" fi="0" fc="16777215" co="0" img="" img2="" page="" pin="" kc="0"/>
  //<el id="9" tid="35" px="575" py="30" sx="60" sy="60" oid1="409876" oid2="-1" oid3="0" oid4="-1" ost1="0" ost2="0" type="1" txt="Open" fn="Arial" fs="12" fb="1" fi="0" fc="16777215" co="8421504" img="" img2="" page="" pin="" kc="1621272"/>


  if(props.tid==302){
    // object id1 and oid2 both populated
    // 1 is control item
    // 2 is the feedback item


    let slider_value=parseInt(getVariable(props.vars,props.oid1,0))

    //console.log(slider_value)
    //    <Circle className="dragable" cx={parseInt(props.px)+10} cy={parseInt(props.py)+200} r="10" fill="white" />
    //<div className="divslider"></div>
    return (<g key={"EL"+props.id} className="svgButton">
    <text  textAnchor="left" transform={"translate("+(parseInt(props.px)+5)+","+props.py+") rotate(90)"} fill={colorHex(props.fc)}>{props.txt}</text>

    <text x={parseInt(props.px)} y={parseInt(props.py)+parseInt(props.sy)} textAnchor="right" dominantBaseline="right"  fill={colorHex(props.fc)}>{slider_value}</text>







    <PSlider
        x={parseInt(props.px)+28}
        y={parseInt(props.py)-5}
        attrib={props.attrib}
        sy={parseInt(props.sy)}
        vertical={true}
        railStyle={{ backgroundColor: 'grey'}}
        trackStyle={{ backgroundColor: '#007dff'}}
        handleStyle={{
                borderColor: 'white',
                height:28,
                width:28,
                marginLeft:-12,
                backgroundColor: 'white',
        }}
        onChange={(e)=>props.callback(props.attrib,"sliderUpdate2",e)}
        onAfterChange={props.callback}
        min={0} max={100} step={1}
        value={slider_value}

            />

    </g>)
  }

  if(props.tid==301){
    // object id1 and oid2 both populated
    // 1 is control item
    // 2 is the feedback item


    let slider_value=parseInt(getVariable(props.vars,props.oid1,0))

    //console.log(slider_value)
    //    <Circle className="dragable" cx={parseInt(props.px)+10} cy={parseInt(props.py)+200} r="10" fill="white" />
    //<div className="divslider"></div>
    return (<g key={"EL"+props.id} className="svgButton">
    <text  textAnchor="left" transform={"translate("+(parseInt(props.px)+5)+","+props.py+") rotate(90)"} fill={colorHex(props.fc)}>{props.txt}</text>

    <text x={parseInt(props.px)} y={parseInt(props.py)+parseInt(props.sy)} textAnchor="right" dominantBaseline="right" fill={colorHex(props.fc)}>{slider_value}</text>


        <PSlider
            x={parseInt(props.px)+28}
            y={parseInt(props.py)-5}
            attrib={props.attrib}
            sy={parseInt(props.sy)}
            onAfterChange={props.callback}
            min={0} max={100} step={1}
            value={slider_value}
        />

    </g>)
  }

  if(props.tid==700){
    //<el id="8" tid="701" px="90" py="25" sx="480" sy="360" oid1="http://192.168.1.170/cgi-bin/mjpg/video.cgi?" oid2="" oid3="" oid4="-1" ost1="0" ost2="0" type="8" txt="" fn="Arial" fs="12" fb="1" fi="0" fc="16777215" co="0" img="" img2="" page="" pin="" kc="1621272"/>
    let video_style={
      position:"fixed",
      left:"0px",
      top:"0px"
    }
    return (<g key={"EL"+props.id} >
    <rect  x={props.px+"px"} y={parseInt(props.py)+"px"}  width={parseInt(props.sx)+"px"} height={parseInt(props.sy)+"px"}></rect>

    <foreignObject x={parseInt(props.px)} y={parseInt(props.py)}  width={parseInt(props.sx)} height={parseInt(props.sy)} >
  <iframe className="iframe_fix" src={props.oid1} title="---"  ></iframe>
    </foreignObject>
    </g>)
  }

/*<video width={parseInt(props.sx)} height={parseInt(props.sy)} controls style={video_style} autoplay>
<source src={props.oid1} type="video/mp4" />
</video>*/

  if(props.tid==701){
    //<el id="8" tid="701" px="90" py="25" sx="480" sy="360" oid1="http://192.168.1.170/cgi-bin/mjpg/video.cgi?" oid2="" oid3="" oid4="-1" ost1="0" ost2="0" type="8" txt="" fn="Arial" fs="12" fb="1" fi="0" fc="16777215" co="0" img="" img2="" page="" pin="" kc="1621272"/>
    let video_style={
      position:"fixed",
      left:"0px",
      top:"0px"
    }
    return (<g key={"EL"+props.id} >
    <rect  x={props.px+"px"} y={parseInt(props.py)+"px"}  width={parseInt(props.sx)+"px"} height={parseInt(props.sy)+"px"}></rect>

    <foreignObject x={parseInt(props.px)} y={parseInt(props.py)}  width={parseInt(props.sx)} height={parseInt(props.sy)} >
    <iframe className="iframe_fix" src={props.oid1} title="---"  ></iframe>


    </foreignObject>
    </g>)
  }
  /*<video width={parseInt(props.sx)} height={parseInt(props.sy)} controls style={video_style} autoplay>
        <source src={props.oid1} type="video/mp4" />
  </video>*/




  return(<text key={"EL"+props.id} x={parseInt(props.px)+parseInt(props.sx)/2} y={parseInt(props.py)-(parseInt(props.sy)/2)+offset_y+105}>{props.tid}</text>)
}



export default class GuiWindowMain extends Component {
  constructor(props) {
    super(props);
  }



  requestStateUpdate(data){
    let update_tid=["301","302","100","102","1200","600","1100"]

    for (let item in data){
    //  console.log(data[item].attributes.tid)
      if(update_tid.includes(data[item].attributes.tid)){

          if(data[item].attributes.oid1!=-1){
      //      console.log("request state for "+data[item].attributes.oid1)
        this.props.callback(data[item].attributes,"state",0)
      }
      }

    }
  }


  componentDidMount(){

    let gsl_index=this.props.gsl_index
    let gml_index=this.props.gml_index

    console.log("Mounting...")
    this.requestStateUpdate(this.props.data.children)
    /*Draggable.create("guiswipe", {
      trigger:".gui_"+gml_index+"_"+gsl_index,
      type: "x",
      minimumMovement: 1,
      onDragStart:function(){
        console.log("Swipe")
      },
      onDragEnd:function(){
        console.log("Swipe End")
      }

    });*/


  }



  componentDidUpdate(prevProps, prevState) {
  //  console.log(prevProps.gml_index)
    //console.log(this.props.gml_index)
    //co/nsole.log(prevState)

    if (prevProps.gml_index != this.props.gml_index || prevProps.gsl_index != this.props.gsl_index) {
      console.log("PAGE CHANGE we must request state for any obejects that require it")
      this.requestStateUpdate(this.props.data.children)
    }
  }


  componentWillUnmount(){
    console.log("unmounting...")
  }

  render(){
    var props=this.props
    if(props.data.length==0){
      return (<span></span>)
    }

    let gsl_index=this.props.gsl_index
    let gml_index=this.props.gml_index
    let style={}
    /*for(gsl_index=0; gsl_index<props.data[gml_index].children.length; gsl_index++){
      if(props.data[gml_index].children[gsl_index].attributes.id==props.gsl_id){
          break;
      }
    }*/
    let viewbox="0 0 640 480"
    let width="950px"
    let height="760px"
    if (this.props.iphone==true){
      viewbox="0 0 320 480"
      width="95%"
      height="100%"
      style={"padding-top":"0px"}
    }


    let hidden_pages=false
    let hidden_array=[]
    let hidden_page_x=300
    let hidden_page_y=410
    if("hidden_index" in this.props.data.attributes){
      hidden_pages=true
      if(this.props.iphone==true){
        hidden_page_x=120
        let px_y=(480/window.screen.height)
        hidden_page_y=(480/window.screen.height)*(window.screen.height*0.9)
      }
      for(let i=1;i<=this.props.data.attributes.hidden_total;i++){

        if(i==this.props.data.attributes.hidden_index){
          hidden_array.push({"circle":true,r:8,page:this.props.data.attributes.id,x:hidden_page_x})
        }
        else{
          hidden_array.push({"circle":true,r:4,page:parseInt(this.props.data.attributes.hidden_start)+i-1,x:hidden_page_x})
        }



      }


    }
  //  console.log(this.props.data.attributes)
      return(


         <svg key={"gui_"+gml_index+"_"+gsl_index} viewBox={viewbox} width={width} height={height} style={style} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
         <linearGradient id="greyBut" gradientTransform="rotate(90)">
              <stop offset="5%"  stopColor="#cdcdcd" />
              <stop offset="95%" stopColor="#6f6f6f" />
        </linearGradient>
        <linearGradient id="greyBut1" gradientTransform="rotate(90)">
             <stop offset="5%"  stopColor="#333333" />
             <stop offset="95%" stopColor="#6f6f6f" />
       </linearGradient>
        {props.data.children.map((el,i)=><EL key={"gui_"+gml_index+"_"+gsl_index+"_"+el.attributes.id} {...el.attributes} attrib={el.attributes} vars={props.vars} callback={props.callback}></EL>)}

        {hidden_pages==true ?
            (<g>
          {hidden_array.map((d,i)=><circle key={"circ"+i} cx={(d.x+i*35)} cy={hidden_page_y} r={d.r}  fill="#FFFFFF" strokeWidth="30" stroke="#FF000000" onClick={()=>props.callback(this.props.attrib,"changePage",d.page)}></circle>)}
          </g>



          ):("")}


        </svg>
      )






  }



}
