import React, { Component }  from 'react';
import XMLParser from 'react-xml-parser';
import GuiWindowMain from "./GuiWindowMain.js"

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';

//import {PubSub, Auth, API } from "aws-amplify";

import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api';
import PubSub from "@aws-amplify/pubsub";

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}


function getBrowserVisibilityProp() {
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    return "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange";
  }
}

export function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  } else if (typeof document.msHidden !== "undefined") {
    return "msHidden";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden";
  }
}

function swf2png(img){
  if(img.includes(".swf")){
    return "/img/"+img.replace(".swf",".png")
  }
  return "/img/"+img
}

function Gml(props){
  let extraClass=""
  if(!("id" in props))
    return ("")


  if (props.id==props.active_id){
    extraClass="btn_active"
  }
  let width="72px"

  if(props.iphone){
    width="32px"
  }
  let image_mode=0
  let style={}
  if(props.mainImg1.length!=0){
    image_mode=1
  //  console.log(props.mainImg1)

    width="48px"
    if(props.iphone){

    }
    style={
      "padding-top":"15px",
      "padding-bottom":"15px",
      "width":"115px",
      "background-image": "url('"+swf2png(props.mainImg2)+"')",
      "background-size": "100% 100%"
    }
    if (props.id==props.active_id){
      style["background-image"]="url('"+swf2png(props.mainImg1)+"')"
    }
    if(props.iphone){
      style["width"]="32px"
      style["padding-top"]="5px"
      style["padding-bottom"]="5px"
      style["width"]="32px"
      width="16px"
    }

  }
  if(props.iphone==true){
    width="48px"
    return (<div className="iphone_gml" onClick={()=>props.callback(props.id)}>
              <div className="divButton">
                <img src={swf2png(props.img)} alt="" width={width} height={width}></img>
              </div>
              <span className="buttonTextIphone"><center>{props.txt}</center></span>
            </div>)
  }



if(image_mode==1){
  return (<div className={"gmlButtonImg "+extraClass} style={style} test={extraClass} onClick={()=>props.callback(props.id)}>
            <div className="divglmButtonImg" >
              <img src={swf2png(props.img)} alt="" width={width} height={width}></img>
            </div>
            <span className="buttonText"><center>{props.txt}</center></span>
          </div>)
}


return (<div className={extraClass} onClick={()=>props.callback(props.id)}>
          <div className="divButton grey_button">
            <img src={swf2png(props.img)} alt="" width={width} height={width}></img>
          </div>
          <span className="buttonText"><center>{props.txt}</center></span>
        </div>)
}

function IphoneMenu(props){
  return (<div className="iphone_menu">
            <div className="iphone_menu_content">



            {props.data.map((e,i)=><Gml key={"btm_"+e.attributes.id+"_"+props.gml_id} {...e.attributes} iphone={props.iphone} callback={props.callback} active_id={props.active_id} gml_index={props.gml_index} mainImg1={""} mainImg2={""}></Gml>)}

            </div>
          </div>)
}

function LeftBar(props){

let extra_style={}
if(props.mainImg1.length!=0){
  //Add the offset
  extra_style={
    top:"96px",
    height:"561px",
    width:"114px",
   "scrollbar-width": "none",
   "overflow-x":"hidden"
  }

  if(props.iphone==true){
    extra_style["width"]="32px"
    extra_style["top"]="0px"
    extra_style["height"]="100%"
  }




}


if(window.devicePixelRatio > 1){
      extra_style["minHeight"]="100vh"
}



return(
  <div key={"leftbarinner"+window.innerWidth+"_"+props.rerender} className="leftbar" id="leftMenu" style={extra_style}>
    {props.data.map((e,i)=><Gml key={"btm_"+e.attributes.id+"_"+props.gml_id} {...e.attributes} iphone={props.iphone} callback={props.callback} active_id={props.active_id} gml_index={props.gml_index} mainImg1={props.mainImg1} mainImg2={props.mainImg2}>{i}</Gml>)}
  </div>)

}


function Gsl(props){
  let extraClass=""
  let style={}
  if (props.id==props.active_id){
    extraClass="btn_active"
  }

  if(props.hi==1){
    return (<span></span>)
  }
  if(props.iphone==true){
    return (<div className={"gslButton "+extraClass} onClick={()=>props.callback(props.id)}>
          <div className="divButton ">
          <img src={swf2png(props.img)} alt="" width="32px" height="32px"></img>
          </div>
          <span className="buttonTextIphone"><center>{props.txt}</center></span>
          </div>)

  }


  if(props.image_mode==1){

    style={
      "background-image": "url('/"+props.subImg2+"')",
      "width":"115px",
      "background-size": "100% 100%"
    }
    if (props.id==props.active_id){
      style["background-image"]="url('/"+props.subImg1+"')"
    }
    //The gui has specified images to use with the gui
    return (<div className={"gslButtonImg "+extraClass} style={style} onClick={()=>props.callback(props.id)}>
          <div className="divButtonImg ">
          <img src={swf2png(props.img)} alt="" width="48px" height="48px"></img>
          </div>
          <span className="buttonText"><center>{props.txt}</center></span>
          </div>)



  }



  return (<div className={"gslButton "+extraClass} onClick={()=>props.callback(props.id)}>
        <div className="divButton grey_button">
        <img src={swf2png(props.img)} alt="" width="64px" height="64px"></img>
        </div>
        <span className="buttonText"><center>{props.txt}</center></span>
        </div>)
}

function BottomBar(props){
  let style={}
  let gml_index=props.gml_index
  if(gml_index==-1){
    return ("")
  }

//  console.log("x")


  if(props.data.length==0){
    return ("")
  }


  //console.log(gml_index)
  let image_mode=0

  if(props.subImg1.length!=0){
    image_mode=1;
    style={
      height:"90px"
    }
  }

  if(props.iphone==false){
      style["width"]="999px"
    if(props.data.children.length>7){
        style["width"]="100vw"
    }
  }

  if(window.devicePixelRatio > 1){
        style["width"]="100vw"
  }


  if(props.iphone==true){
    style={
      height:"80px",
      left:"0px",
      "padding-right":"50px"
    }
    if(props.data.children.length>7){
      console.log("New len")
      console.log(props.data.children.length)
      console.log((props.data.children.length*30).toString()+" px")
      style["width"]=window.window.outerWidth.toString()+"px"
    }

  }


  return(
      <div key={"Bottombar_innser"+window.innerWidth+props.rerender} className="bottombar" style={style} key={"BB"+gml_index}>

      {props.iphone==true?(
      <div className={"gslButton"} onClick={()=>props.callback(-1)}>
            <div className="divButton">
            <img src={swf2png("c_back.png")} alt="" width="32px" height="32px"></img>
            </div>
            <span className="buttonTextIphone"><center>Back</center></span>
            </div>):("")}

    {props.data.children.map((el,i)=><Gsl key={gml_index+"_"+el.attributes.id} {...el.attributes} callback={props.callback} active_id={props.gsl_id} image_mode={image_mode} subImg1={props.subImg1} subImg2={props.subImg2} iphone={props.iphone}></Gsl>)}
    </div>



  )


}

function dec2bin(dec){
    return (dec >>> 0).toString(2);
}

function pad(value,target_length,pad_value="0"){
  while(value.length<target_length){
    value=pad_value+value
  }
  return value
}

export default class Gui extends Component {
    constructor(props) {
      super(props);

      this.state={
        connected:0,
        email:"",
        password:"",
        isAuthenticted:false,
        isAuthenticating:true,
        initialLoad:true,
        initialLoadText:"",
        cognitoId:"",
        gml_id:0,
        gsl_id:0,
        gui_json:{"children":[]},
        gui_vars:{"354522":["Mem_temp_Set18",23,1,22.00]},
        feedback:"Not Connected",
        pageChange:true,
        gml_index:-1,
        gsl_index:-1,
        first_connect:true,
        offline_message:[],
        modalopen:false,
        mon:true,
        tue:false,
        wed:false,
        thu:true,
        fri:true,
        sat:false,
        sun:false,
        sch_name:"",
        sch_enabled:false,
        sch_time:"12:00",
        sch_id:0,
        iphone:false,
        background:"sensio_background640x480.jpg",
        mainImg1:"",
        mainImg2:"",
        subImg1:"",
        subImg2:"",
        version:"01-07-2021.1",
        focus:true,
          rerender:0,
        reload:false,
        pi_id:"",
        feedback_h1:"Loading",
        gui_data:{},
        username:"",
        password:"",
        login_required:false,
        dialog_open:true,
        login_feedback:""
      }
      this.handleChange = this.handleChange.bind(this);

      //span
    }
    userHasAuthenticated = async authenticated => {
      console.log("User authenticated")
      let user = await Auth.currentAuthenticatedUser()
    //  console.log(user)
      this.setState({ isAuthenticated: authenticated });
      if(authenticated===true){
        this.setState({feedback_h1:"Authenticated"})
        console.log("Attempting API")
        Auth.currentCredentials().then((info) => {
          //console.log(info)
            const cognitoIdentityId = info.data.IdentityId;
            console.log(info)
            console.log(cognitoIdentityId)
            this.setState({cognitoId:cognitoIdentityId})


        });
        return true
      }
      else
        return false



    }

    getInfo() {
  return API.get("AltiomData", "/welcome");
}





remoteComsCheck(){

  if (this.state.initialLoad==true){
   this.sendit({cmd:"RemoteProg",type:"connect",id:this.state.cognitoId})
  // this.timer = setTimeout(() => this.remoteComsStart(), 3000);
   let temptext=this.state.initialLoadText
   this.setState({"initialLoadText":temptext+"."})
   if(this.state.initialLoadText.includes("....................")==true){
     console.log("this has been going a while, lets give up?")
     this.setState({"initialLoadText":"Aborted communication attempt"})
   }else{
     this.timer = setTimeout(() => this.remoteComsCheck(), 3000);
   }
 }else{
   console.log("We have had our inital update we need to keep the connection alive or the altiom will disconnect us!")
   this.sendit({cmd:"RemoteProg",type:"refresh",id:this.state.cognitoId})
   this.timer = setTimeout(() => this.remoteComsCheck(), 60000);
 }


 let currentTime=new Date().getTime()
 let timeDiff=currentTime-this.state.last_seen
 console.log(currentTime-this.state.last_seen)


 if(this.state.initialLoad==false){
 if(timeDiff>32000 && timeDiff<60000){
   //NotificationManager.warning('Not had a message in last 30 second!', 'Connection', 2000);
   console.log("Not had a message in 30s")
 }

 if(timeDiff>60000){
   //NotificationManager.error('Possible loss of communication to Altiom', 'Connection', 60000);
   this.setState({"initialLoadText":"Realtime datalink ended. Refresh page"})
   this.setState({initialLoad:true})
 }

}





 //When was the last time we recieved a message from the altiom?



}



    calc_xml_index=(gml,gsl)=>{
      //the gml left hand pages and gml bottom pages indexs dont match the indexes in the xml, so this looks them up, or it could map them
      let gml_index=0
      let data=this.state.gui_json.children
      for(gml_index=0; gml_index<data.length; gml_index++){
            if(parseInt(data[gml_index].attributes.id)==gml){
              break;
        }
      }
      if(gml_index==data.length){
        gml_index=-1
      }




      let gsl_index=0
      for(gsl_index=0; gsl_index<data[gml_index].children.length; gsl_index++){
        if(data[gml_index].children[gsl_index].attributes.id==gsl){
            break;
        }
      }

      if(gsl_index==data[gml_index].children.length){
          gsl_index=-1
      }


      this.setState({gml_index:gml_index,gsl_index:gsl_index})

    //  console.log({gml_index:gml_index,gsl_index:gsl_index})
    }

    load_gml=(id)=>{
      console.log("Hello")
      console.log(id)
      this.setState({gml_id:id,gsl_id:0})
      this.calc_xml_index(id,0)

    //  this.setState({"pageChange":!t})
    }


    changeGsl=(id)=>{

      //If its -1 it means go back to iphone main menu
      if(id==-1){
      this.setState({gml_id:-1})
      }
      else{
        this.setState({gsl_id:id})
        this.calc_xml_index(this.state.gml_id,id)
      }


    }

resend_offline=(data)=>{

  let i=0;
  for(i=0;i<data.length;i++){
    console.log(data[i])
    this.gui_callback(data[i][0],data[i][1],data[i][2])
  }


}

gui_callback=(attrib,type,value)=>{
  if(type=="sliderUpdate"){
    let slide_var_temp=[0,0,0,0,0]
    if(attrib.oid1 in this.state.gui_vars){
      slide_var_temp=this.state.gui_vars[attrib.oid1]
    }
    slide_var_temp[4]=value
    let tempvar=this.state.gui_vars
    tempvar[attrib.oid1]=slide_var_temp

    this.setState({gui_vars:tempvar})
    return
  }
  if(type=="changePage"){
    console.log("Change PAge")
    console.log(value)
    this.changeGsl(value)
    return
  }

  let feedback="id:"+attrib.oid1+" type:"+type+" value:"+value
  //console.log(attrib)

  //console.log((attrib.oid1))
  //console.log(attrib.page.length)

  if(this.state.connected==0){
    console.log("OFFLINE")
    if(this.state.first_connect==true){
      let offline=this.state.offline_message
      offline.push([attrib,type,value])
      this.setState({offline_message:offline})
    }
    console.log([attrib,type,value])
    return
  }
  try{
    console.log("---290538---")
    console.log(this.state.gui_vars["290538"])
  }catch(err){

  }
  if(type=="changePage"){
    this.changeGsl(value)
  }

  if(type=="state"){
    if (this.state.connected==0){
      return
    }
    let message={"cmd":"tx","data":"status "+attrib.oid1}
    this.send(JSON.stringify(message));
  }

  if(type=="schedule_toggle"){
    if (this.state.connected==0){
      return
    }
    let message={"cmd":"tx","data":"set_enable "+attrib.oid1+" "+(parseInt(value)^1)}
    this.send(JSON.stringify(message));

  }
  if(type=="toggle"){
    if (this.state.connected==0){
      return
    }
    let message={"cmd":"tx","data":"new_state "+attrib.oid1+" "+value}
  //  alert(JSON.stringify(message))
    this.send(JSON.stringify(message));
  }
  if(type=="button"){
    if (this.state.connected==0){
      return
    }
    let message={"cmd":"tx","data":"new_state "+attrib.oid1+" 0"}
    this.send(JSON.stringify(message));
  }
  if(type=="slider"){
    if (this.state.connected==0){
      return
    }

    let message={"cmd":"tx","data":"set_value "+attrib.oid1+" "+value}
    this.send(JSON.stringify(message));
    message={"cmd":"tx","data":"new_state "+attrib.oid2+" 0"}
    this.send(JSON.stringify(message));

  }

  if(type=="schedule_edit"){
    //Load up data for this item into the schedule
    let sch_data=["T_Heat_WE_Night", "4", "0", "0", "1", "0", "000"]
    this.setState({sch_id:attrib.oid1})
    if ( (attrib.oid1 in this.state.gui_vars) ){
        sch_data=this.state.gui_vars[attrib.oid1]
    }

    this.setState({sch_name:attrib.txt})
    this.setState({sch_time:new Date(sch_data[6] * 1000).toISOString().substr(11, 5)})
    this.setState({sch_enabled:sch_data[2] === '1' ? true :false})
    let days=pad(dec2bin(sch_data[5]),8,"0")
    days=days.split("").reverse()
    const daynames=["sun","mon","tue","wed","thu","fri","sat"]
    for(let i=0;i<days.length;i++){
          this.setState({[daynames[i]]:days[i] === '1' ? true :false})
      }
    this.setState({modalopen:true})
  }


  if(attrib.oid1==-1 && attrib.page.length!=0){
    //its a page change
  //this.setState({feedback:attrib.page})
  this.changeGsl(parseInt(attrib.page.split("-")[1]))

}

//  this.setState({feedback:feedback})

  console.log("id:"+attrib.oid1+" type:"+type+" value:"+value)
  console.log(type)
  console.log(value)

}
subscibeError=(error)=>{
  console.log("Subscibe Failed")
  console.log(error)
  console.log(this.subscription)
  this.setState({"initialLoadText":"Connection not allowed"})

}
processMsg=(lastmsg)=>{
  console.log("process msg")
  console.log(lastmsg)
      this.setState({"last_seen":new Date().getTime()})
//  console.log(message)
  //let lastmsg=JSON.parse(message.data)

if("data" in lastmsg){
  if(lastmsg.data.connected==true){
    console.log("WE connected")
    this.setState({"connected":1})
    this.setState({initialLoad:false})
    this.setState({"feedback":"Connected"})
    //return
  }
}

  if(this.state.connected==0){
    //we are recieving a message from the controller so we must be connected!
    this.setState({"connected":1})
        this.setState({initialLoad:false})
    this.setState({"feedback":"Connected"})
  }


  if("packet" in lastmsg){
    console.log("have packet")
    let temp=lastmsg.packet.split(" ")
    if(temp[0]=="RSN"){
      //Its data values
      let tempvar=this.state.gui_vars
      tempvar[temp[1].toString()]=temp.slice(2)
      console.log(tempvar[temp[1].toString()])
      this.setState({gui_vars:tempvar})
    }


  }
}

//Connect to the altioms websocket for realtime updates
  wsConnect = () => {
    console.log("Attempting websocket connection")
    console.log("I think this is an effect of the way it dynamicly updates things")

  //  alert(this.state.gui_data.id)
    //Do i try and do local?

    this.subscription=PubSub.subscribe(this.state.gui_data.id+'/remote/'+this.state.cognitoId).subscribe({
        next: data => {
          console.log(data)
          console.log("Run Callback")
          if(this.state.first_connect==true){
            this.setState({first_connect:false})
            console.log("WEBSOCKET INITIAL FIRST MESSAGE")
            this.setState({"connected":1})
            this.resend_offline(this.state.offline_message)

            this.setState({offline_message:[]})
          }
          this.processMsg(data.value)

        },
        error: error => this.subscibeError(error),
        close: () => console.log('Done'),
    });
    let connect_str={cmd:"RemoteProg",type:"connect",id:this.state.cognitoId}
    console.log(connect_str)
    this.send(JSON.stringify(connect_str))
      this.timer = setTimeout(() => this.remoteComsCheck(), 5000);

    return


    if(window.location.host=="localhost:3000"){
    this.socket = new WebSocket("ws://192.168.10.187/ws");
    console.log("ws://"+window.location.hostname+":8081/ws")
    }else{
    this.socket = new WebSocket("ws://"+window.location.host+"/ws");
    console.log("ws://"+window.location.host+"/ws")
    }
    console.log("trying connect")
            this.setState({"feedback":"Attempting reconnect..."})
    this.socket.onopen = this.wsOpen
    this.socket.onmessage =(message)=>{
      this.processMsg(JSON.parse(message.data))
      return
    //  console.log(message.data)
      //console.log(JSON.parse(message.data))
      this.lastmsg=JSON.parse(message.data)
      console.log(this.lastmsg)


      if(this.lastmsg.connected==true){
        console.log("WE connected")
        this.setState({"connected":1})
        this.setState({"feedback":"Connected"})

        return
      }

      let temp=this.lastmsg.packet.split(" ")
      if(temp[0]=="RSN"){
        //Its data values
        let tempvar=this.state.gui_vars
        tempvar[temp[1].toString()]=temp.slice(2)
        console.log(tempvar[temp[1].toString()])
        this.setState({gui_vars:tempvar})
      }

    }

      this.socket.onclose =(e)=>{
        console.log(e)
        console.log("Websocket has disconnected")
        this.setState({"connected":0})
        this.setState({"feedback":"Disconnected"})
      //  window.location.reload()
        setTimeout(() =>this.wsConnect(), 1000);
      }

      this.socket.onerror=(e)=>{
          console.log("Socket Error?")
          this.setState({"feedback":"Disconnected"})
          //window.location.reload()
           setTimeout(() =>this.wsConnect(), 1000);

      }
    }
    wsOpen=()=>{

      console.log("Websocket opened origin disabled ")
      this.setState({"feedback":"Connected"})

    }

    send=(msg)=>{




if(this.state.cognitoId.length!=0){
  console.log("Sending online")
    API.post("AltiomData", "/altiom/"+this.state.gui_data.id,{body:JSON.parse(msg)}).then(response => {
    //  console.log(response)
    }).catch(error => {
      console.log(error.response)
    });
  }else{

    try{
      this.socket.send(msg)
    }catch(err){}

  }
    }

    sendit=(msg)=>{




if(this.state.cognitoId.length!=0){
  console.log("Sending online")
    API.post("AltiomData", "/altiom/"+this.state.gui_data.id,{body:msg}).then(response => {
      console.log(response)
    }).catch(error => {
      console.log(error.response)
    });
  }else{

    try{
      this.socket.send(JSON.stringify(msg))
    }catch(err){}

  }
    }

    getAccess(id,file){
      let data = {
        'queryStringParameters': {
            'file': file,
            'id':id,
        }
      }
      return API.get("AltiomData", "/welcome",data);
}


  dologin = async event => {



    this.setState({ isLoading: true });
    try {
      const r1=await Auth.signIn(this.state.username, this.state.password);
      console.log(r1)
      const r=await this.userHasAuthenticated(true);
      console.log(r)
      this.setState({login_feedback:"Login successful"})
      this.reload_window()
      //  this.props.history.push("/");
    } catch (e) {
      this.setState({login_feedback:"Failed to login"})
      if(e.code=="UserNotConfirmedException"){
        this.setState({showCode:true})
      }
    }
}


    async componentDidMount(){
        let gui_options=[]
        try {
        console.log("Component did mount")

        if (await Auth.currentSession()) {
          this.userHasAuthenticated(true);
          console.log("DATA?")
          this.setState({feedback_h1:"Gathering info"})
          const data = await this.getInfo();
          console.log("RESULT")
          console.log(data)
          this.setState({feedback_h1:"Loaded user profile"})

          for (let item in data.altiom){
            //console.log(data.altiom[item])
            if("mode" in data.altiom[item]==false){
                //Its an individual altiom
                if("controlight" in data.altiom[item].data){
                    if(data.altiom[item].data.controlight==true){
                      gui_options.push(data.altiom[item])

                    }

                }
                console.log(data.altiom[item]["mode"]=="sensio")
                  //Gui found
            }


          }
      //    const file_link=await this.getAccess("test","init.xml")

    //      console.log(file_link)
      //    const data = await this.getdata();
      //    this.setState({
      //    "data":data.data
      //  });
        }
      }
      catch(e) {
        console.log(e)
        if (e !== 'No current user') {
          console.log(e)
        }else{

          this.setState({"login_required":true})


        }

    }
console.log(gui_options)

    if(gui_options.length==0){
      //alert("No guis assocaiated with this account")
      this.setState({feedback_h1:"No guis assocaiated with account"})
    }
    else if(gui_options.length==1){
        //we have just one gui so load that up
        this.setState({gui_data:gui_options[0]})
        this.load_gui_data()

    }else{
      this.setState({feedback_h1:"Multiple guis associated"})

      if(window.location.search.split("=")[1]!=undefined){
        //Been asked to load a particular gui
        let req=window.location.search.split("=")[1]
        for(let i=0;i<gui_options.length;i++){
          if(gui_options[i].id==req){
            this.setState({gui_data:gui_options[i]})
            this.load_gui_data()

          }
        }
      }else{

        this.setState({gui_data:gui_options[0]})
        this.load_gui_data()
      }
    }





    }


    async load_gui_data(){

            this.setState({ isAuthenticating: false });


            this.setState({iphone: window.innerWidth <= 760});
            //this.setState({iphone: true});


            this.wsConnect()
          //  var jsonDataFromXml = new XMLParser().parseFromString(xmlData);



          let xml_file="/conf/init.xml"
          //xml_file="iphone.xml"
          let file_link={}
          if( window.innerWidth <= 760){
            xml_file="/conf/iphone.xml"
            file_link=await this.getAccess(this.state.gui_data.id,"web/conf/iphone.xml")
              xml_file=file_link.link
          }else{
            file_link=await this.getAccess(this.state.gui_data.id,"web/conf/init.xml")
            xml_file=file_link.link
          }
          this.setState({feedback_h1:"Downloading gui"})


      /*
          if(this.state.iphone==true){
            window.screen.orientation.lock("portrait").then( () => {
                console.log("Locked portrait")
            });
          }else{
            window.screen.orientation.lock("landscape").then( () => {
                console.log("landscape")
            });
          }
      */
      let cache_worked=false
      if(localStorage.getItem("xml_data")!=null){
        try{
        //  alert("Cache")
          let jsonDataFromXml=JSON.parse(localStorage.getItem("xml_data"))

          this.setState({"gui_json":jsonDataFromXml})
          console.log(jsonDataFromXml)

          this.setState({background:jsonDataFromXml.attributes.cimg})
          document.body.style.backgroundImage = "url('/bg.png')";
          if("mainImg1" in jsonDataFromXml.attributes){
            //There are some extra image parameters that override whats in place
            try{
          //  this.setState({mainImg1:jsonDataFromXml.attributes.mainImg1})
//            this.setState({mainImg2:jsonDataFromXml.attributes.mainImg2})
            //this.setState({subImg1:jsonDataFromXml.attributes.subImg1})
            //this.setState({subImg2:jsonDataFromXml.attributes.subImg2})
            }
            catch(err){
              console.log("Failed to find extra images")
            }

          }

          console.log("calculating xml page index from cache")
          this.calc_xml_index(this.state.gml_id,this.state.gsl_id)
          if(this.state.iphone==true){
            this.setState({gml_id:-1})
          }

          cache_worked=true
        }catch(err){
          console.log("Failed lets download")
          cache_worked=false
        }

/*

*/
      }

          if(cache_worked==false)
            fetch(xml_file,{
              method: 'GET'
          }).then(response=>response.text()).then(data=>{
            console.log("XML response")
            console.log(data)
              var jsonDataFromXml = new XMLParser().parseFromString(data);
              console.log(jsonDataFromXml)
              if(jsonDataFromXml.name=="Error"){
                  alert("Gui not found for "+this.state.gui_data.id)
                  this.setState({feedback_h1:"Gui not found"})
                  return
              }
              jsonDataFromXml=this.find_hidden_pages(jsonDataFromXml)

              localStorage.setItem("xml_data",JSON.stringify(jsonDataFromXml) )
              this.setState({"gui_json":jsonDataFromXml})
              console.log(jsonDataFromXml.attributes.cimg)
              this.setState({background:jsonDataFromXml.attributes.cimg})
              document.body.style.backgroundImage = "url('/bg.png')";
              if("mainImg1" in jsonDataFromXml.attributes){
                //There are some extra image parameters that override whats in place
                try{
                this.setState({mainImg1:jsonDataFromXml.attributes.mainImg1})
                this.setState({mainImg2:jsonDataFromXml.attributes.mainImg2})
                this.setState({subImg1:jsonDataFromXml.attributes.subImg1})
                this.setState({subImg2:jsonDataFromXml.attributes.subImg2})
                }
                catch(err){
                  console.log("Failed to find extra images")
                }

              }

              console.log("calculating xml page index")
              this.calc_xml_index(this.state.gml_id,this.state.gsl_id)
              if(this.state.iphone==true){
                this.setState({gml_id:-1})
              }

            })
            this.setState({feedback_h1:"Complete"})
            this.setState({dialog_open:false})


    }


    find_hidden_pages(data){

      let index=0
      let gui_page=0
      let i=0
      let hidden=0
      let hidden_count=1
      let count=0
      for (i=0;i<data.children.length;i++){

        hidden=0
        hidden_count=0
        for(gui_page=0;gui_page<data.children[i].children.length;gui_page++){
            if(data.children[i].children[gui_page].attributes.hi==0){

              if(hidden_count>1){

                //add this data into the object
                for(let j=hidden,count=1;j<(hidden+hidden_count);j++,count++){

                  data.children[i].children[j].attributes.hidden_index=count
                  data.children[i].children[j].attributes.hidden_total=hidden_count
                  data.children[i].children[j].attributes.hidden_start=hidden
                }
              }
              hidden=gui_page
              hidden_count=1;
            }else{
              //this page is hidden
              hidden_count=hidden_count+1
            }

        }
        if(hidden_count>1){

          //add this data into the object
          for(let j=hidden,count=1;j<(hidden+hidden_count);j++,count++){

            data.children[i].children[j].attributes.hidden_index=count
            data.children[i].children[j].attributes.hidden_total=hidden_count
            data.children[i].children[j].attributes.hidden_start=hidden
          }
        }


      }

      return data

    }

    componentWillUnmount(){
      console.log("COMPONENT IS BEING REMOVED")

      try{
        this.wsConnect=null
        this.socket.close()
      }catch(err){}
  }

  save_sched(){


    console.log("Save shcedule")
    let t=this.state.sch_time
    if(t==null)
      return;

    t=parseInt(t.split(":")[0])*3600+parseInt(t.split(":")[1])*60
    //Calc weekdays
    let wdays=[this.state.sat==true   ?"1":"0",
            this.state.fri==true ? "1":"0",
            this.state.thu==true ? "1":"0",
          this.state.wed==true ? "1":"0",
          this.state.tue==true ? "1":"0",
          this.state.mon==true ? "1":"0",
          this.state.sun==true ? "1":"0"]
    wdays=parseInt(wdays.join(""),2)
    // timer id enabled days time
    let enabled=this.state.sch_enabled==true ? 1 : 0;

    let timer_enabled="set_timer "+this.state.sch_id+" 1 "+wdays+" "+t
    console.log(timer_enabled)
    let message={"cmd":"tx","data":timer_enabled}
    this.send(JSON.stringify(message));
    message={"cmd":"tx","data":"set_enable "+this.state.sch_id+" "+enabled}
    this.send(JSON.stringify(message));
    this.modalClose()
  }

  handleChange(e) {
    const target=e.target
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    console.log(name)
    console.log(value);
    if(name=="username"){
        this.setState({[name]:value.toLocaleLowerCase()})
        return
    }
    this.setState({[name]:value})

  }



reload_window=()=>{
    localStorage.removeItem("xml_data")
    window.location.reload(true)
}

debug=()=>{
  this.setState({dialog_open:true})
  return;

  if (window.confirm("Running Version:"+this.state.version+"\nDo you want to reload")) {
    // Save it!
    window.location.reload(true)
  } else {
    // Do nothing!
    console.log('Thing was not saved to the database.');
  }





}


  modalClose=()=>{




    this.setState({modalopen:false})
  }


  logout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    localStorage.clear()
    this.reload_window()
  }

  handleClose = () => {
    this.setState({dialog_open:false})
  };

    render(){



      let props=this.props
      let background_css={
          "backgroundPosition": "left top",
          "backgroundImage": "url('/img/"+this.state.background+"')",
          "backgroundSize":"cover",
          "textAlign": "center",

          "MozBackgroundSize":"100% 100%",
          "WebkitBackgroundSize":"100% 100%",
          "zIndex": -1,
      }
        background_css={}
      if (this.state.iphone==true){
      background_css={}
      }

          return (<div className="iphoneBg" style={background_css}>

            {this.state.modalopen ? (

            <div class="modal">
              <div class="modal-dialog">
              <div class="modal-content">
              <h3>Settings - {this.state.sch_name} <button className="close_button" onClick={()=>this.modalClose()}>X</button></h3>

              <div className="modal-days">
              <p>Enabled:<input name="sch_enabled" type="checkbox" checked={this.state.sch_enabled} onChange={this.handleChange} ></input></p>

              <table className="daysofweek">
                <tr>
                  <th>Mon</th>
                  <th>Tur</th>
                  <th>Wed</th>
                  <th>Thu</th>
                  <th>Fri</th>
                  <th>Sat</th>
                  <th>Sun</th>
                </tr>
                <tr>
                  <td><input name="mon" type="checkbox" checked={this.state.mon} onChange={this.handleChange} ></input></td>
                  <td><input name="tue" type="checkbox" checked={this.state.tue} onChange={this.handleChange} ></input></td>
                  <td><input name="wed" type="checkbox" checked={this.state.wed} onChange={this.handleChange} ></input></td>
                  <td><input name="thu" type="checkbox" checked={this.state.thu}  onChange={this.handleChange} ></input></td>
                  <td><input name="fri" type="checkbox" checked={this.state.fri} onChange={this.handleChange} ></input></td>
                  <td><input name="sat" type="checkbox" checked={this.state.sat} onChange={this.handleChange} ></input></td>
                  <td><input name="sun" type="checkbox" checked={this.state.sun} onChange={this.handleChange} ></input></td>
                </tr>
              </table>

              </div>

              <center><input className="time_input" type="time" onChange={this.handleChange} value={this.state.sch_time} name="sch_time" ></input></center>

              <button className="save_button" onClick={()=>this.save_sched()} >Save</button>

              </div>
              </div>
            </div>):("")}


            {this.state.gml_id==-1 && this.state.iphone==true ? (<IphoneMenu data={this.state.gui_json.children} iphone={this.state.iphone} callback={this.load_gml} active_id={this.state.gml_id} gml_index={this.state.gml_index}></IphoneMenu>):("") }


          {this.state.gui_json!=undefined && this.state.iphone==false ? (<LeftBar key={"leftbar"} data={this.state.gui_json.children} iphone={this.state.iphone} callback={this.load_gml} active_id={this.state.gml_id} gml_index={this.state.gml_index} mainImg1={this.state.mainImg1} mainImg2={this.state.mainImg2}></LeftBar>):("")}


          <div className="top_right" onClick={()=>this.debug()}>{this.state.feedback}</div>


  <div className="mainWindow">
      {/*this sort of needs to be a function that creates and destroys itself*/}
      {this.state.gml_index==-1 ? (<h1></h1>):(<GuiWindowMain key="window1" data={this.state.gui_json.children[this.state.gml_index].children[this.state.gsl_index]} gml_index={this.state.gml_index} gsl_index={this.state.gsl_index} gml_id={this.state.gml_id} gsl_id={this.state.gsl_id} vars={this.state.gui_vars} callback={this.gui_callback} iphone={this.state.iphone}></GuiWindowMain>)}

{/*this.state.gml_index==-1 ? (<h1>LOADING</h1>):(<GuiSlideWindow key="window1" data={this.state.gui_json.children[this.state.gml_index]} gml_index={this.state.gml_index} gsl_index={this.state.gsl_index} gml_id={this.state.gml_id} gsl_id={this.state.gsl_id} vars={this.state.gui_vars} callback={this.gui_callback} iphone={this.state.iphone}></GuiSlideWindow>)*/}

  </div>


{this.state.gui_json!=undefined ? (
        <BottomBar key="Bottombar" data={this.state.gui_json.children[this.state.gml_index]} gsl_index={this.state.gsl_index} gml_index={this.state.gml_index} gml_id={this.state.gml_id} gsl_id={this.state.gsl_id} callback={this.changeGsl} iphone={this.state.iphone} subImg1={this.state.subImg1} subImg2={this.state.subImg2}></BottomBar>):("")}



        <Dialog style={{ zIndex:0 }}
               fullWidth={false}
               maxWidth="800px"
               open={this.state.dialog_open}
               onClose={this.handleClose}
               aria-labelledby="max-width-dialog-title"
             >
               <DialogTitle id="max-width-dialog-title"><center><Avatar variant="square" src="/img/CL SQUARE_transparent_192.png"></Avatar></center></DialogTitle>
               <DialogContent>
                 <DialogContentText>

                 {this.state.login_required==true ? (<div>

                   <TextField size="small" id="outlined-basic" label="Email" variant="outlined" onChange={this.handleChange} value={this.state.username} name="username" /><br/><br/>
                   <TextField size="small" id="outlined-basic" label="Password" variant="outlined" type="password" onChange={this.handleChange} value={this.state.password} name="password"/><br/><br/>
                   <center><Button onClick={this.dologin} variant="contained" color="primary">
                     Login
                   </Button>

                   <p style={{color:"red"}}>&nbsp;{this.state.login_feedback}</p>

                   </center>

                   </div>




                 ):(<span>{this.state.feedback_h1}<br/><br/><br/>
                 <br/><i className="version_text">Version:{this.state.version}</i></span>)}




{/*}*/}
                 </DialogContentText>
               </DialogContent>
               <DialogActions>







               <Button onClick={this.reload_window} color="secondary">
                 Reload
               </Button>
               { this.state.login_required==false ?(<Button onClick={this.logout} color="secondary">Logout
             </Button>):("")}



                 <Button onClick={this.handleClose} color="primary">
                   Close
                 </Button>
               </DialogActions>
             </Dialog>


</div>




)

    }

}
