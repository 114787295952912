import React  from 'react';
import './App.css';
import Gui from './GuiContainer/Gui.js'


function App() {
  return (
    <div className="App">
        <Gui></Gui>
    </div>
  );
}

export default App;
