//import 'react-app-polyfill/ie9';
//import 'react-app-polyfill/ie11';
//import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import Amplify from 'aws-amplify';

import config from "./config";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "AltiomData",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
    ]
  }
});

Amplify.addPluggable(new AWSIoTProvider({
     aws_pubsub_region: 'eu-west-1',
     aws_pubsub_endpoint: 'wss://a3duuhb6j1ul52-ats.iot.eu-west-1.amazonaws.com/mqtt',
   }));


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
