export default {
  s3: {
    REGION: "eu-west-1",
    BUCKET: "altiom-uploads"
  },
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://ny5u1kkza7.execute-api.eu-west-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_LAaJ98s46",
    APP_CLIENT_ID: "3apg27qomkk5k0v1ssrai5kgsr",
    IDENTITY_POOL_ID: "eu-west-1:e0cf2c5d-894c-4fe0-bb1a-2e1b5b7f2534"
  }
};
